import React, { useEffect, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KitchenService } from "../../../services/KitchenService";
import { useLocalStorage } from "../../../auth/useLocalStorage";
import { EShippingType, EStatus, EStatusValue, type Orders } from "../../../common/interfaces";
import DetailsModal from './DetailsModal'
import { CommonService } from "../../../services/CommonService";
interface ListModalProps {
  display: boolean
  setDisplay: (display: boolean) => void
  schedules?: boolean
}

export const ListModal: React.FC<ListModalProps> = ({
  display,
  setDisplay,
  schedules
}) => {
  const [show, setShow] = useState(false);
  const [listOrders, setListOrders] = useState<Orders[]>([]);
  const [user] = useLocalStorage("user", null);
  const [open, setOpen] = useState(false)
  const [data, setDate] = useState<Orders>()
  useEffect(() => {
    // console.log({ display });
    setShow(display);
  }, [display]);

  const hideModal = () => {
    setShow(false);
    setDisplay(false);
    // console.log("hide");
  };
  useEffect(() => {
    if(schedules){
      CommonService.getScheduleOrders(user.warehouse_id).then((res) => {
        if (res.success && res.object) {
          setListOrders(res.object.map(e => e.order));
        }
      })
    }else{
      KitchenService.show(user.kitchen_id as string).then((res) => {
        if (res.success && res.object?.orders) {
          setListOrders(res.object.orders);
        }
      });

    }
  }, []);
  const viewDetail = (order: Orders) => {
    setOpen(true)
    setDate(order)
  }
  return (
    <>
      <Modal
        show={show}
        fullscreen={"lg"}
        onHide={() => {
          hideModal();
        }}
      >
        <Modal.Header
          closeButton
          onHide={() => {
            hideModal();
          }}
        >
          <Modal.Title>Pedidos {schedules ? 'Agendados': ''}</Modal.Title>
        </Modal.Header> 
        <Modal.Body>
          <ListGroup>
            {listOrders.map((order: Orders, i) => (
              <ListGroup.Item
                key={i}
                as="li"
                className="d-flex justify-content-between align-items-start"
                onClick={() => viewDetail(order)}
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Orden # {order.id}</div>$ {order.total}{" "}
                  Tipo:{' '}
                  {EShippingType[
                    order.shipped_type as keyof typeof EShippingType
                  ] ?? ""} <br />
                  Cliente: {order.cliente.nombre}
                </div>
                <Badge bg={order.payment_status === 'paid' ? "success" : "danger"} pill style={{ marginTop: "15px", marginRight: "5px" }}>
                  {order.payment_status === 'paid' ? "Pagado" : "Sin Pago"}
                </Badge>
                <Badge bg={EStatus[order.status as keyof typeof EStatus]} pill style={{ marginTop: "15px" }}>
                  {EStatusValue[order.status as keyof typeof EStatusValue]}
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
      <DetailsModal open={open}  setOpen={setOpen} data={data} />
    </>
  );
};
