import { IKitchen, IResponse } from "../common/interfaces";
import { request } from "./AjaxService";

export const KitchenService = {
  createSession: async (payload: Record<string, any>): Promise<IResponse<any>> => await request.post('kitchen', payload),
  addOrder: async (kitchenId: string, payload: Record<string, any>): Promise<IResponse<any>> => await request.post(`kitchen/${kitchenId}/order`, payload),
  show: async (kitchenId: string): Promise<IResponse<IKitchen>> => await request.get(`kitchen/${kitchenId}`),
  update: async (kitchenId: string, payload: Record<string, any>): Promise<IResponse<any>> => await request.put(`kitchen/${kitchenId}`, payload),
  trackOrderStatus: async (orderId: string, payload: Record<string, any>): Promise<IResponse<any>> => await request.put(`order/${orderId}/change/status`, payload),
  getHistoryOrderStatus: async (orderId: string): Promise<IResponse<any>> => await request.get(`order/${orderId}/history`),
}

/* Route::post('kitchen', [KitchenController::class, 'create']);
Route::post('kitchen/{id}/order', [KitchenController::class, 'addOrder']);
Route::get('kitchen/{id}', [KitchenController::class, 'show']);
Route::put('kitchen/{id}', [KitchenController::class, 'update']);
Route::put('order/{id}/change/status', [KitchenController::class, 'trackOrderStatus']);
Route::get('order/{id}/history', [KitchenController::class, 'getHistoryOrderStatus']); */