import Pusher from "pusher-js/types/src/core/pusher";
import React from "react";

const PusherContext = React.createContext({
  pusher: {} as Pusher,
});

interface PusherProviderProps {
  pusher: Pusher;
  children: React.ReactNode;
}

const PusherProvider: React.FC<PusherProviderProps> = ({ pusher, children }) => {
  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
}

const usePusher = () => {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error("usePusher must be used within a PusherProvider");
  }

  const { pusher } = context;
  return pusher;
}

export { PusherProvider, usePusher };