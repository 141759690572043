import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useLocalStorage } from "../auth/useLocalStorage";

interface IUser {
  token?: string
  userId?: string
  userName?: string
  login?: any
  logout?: any
}

interface IProps {
  children: ReactNode;
}

const baseUser: IUser = {}

export default function PrivateRoute({
  children
}: IProps) {
  const [user,] = useLocalStorage("user", baseUser);
  const isLoggedIn = user?.token ? true : false
  if(isLoggedIn){
    return children
  }
  return <Navigate to="/login" replace/>
 
}
