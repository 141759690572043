import React, { useEffect, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KitchenService } from "../../../services/KitchenService";
import { useLocalStorage } from "../../../auth/useLocalStorage";
import { EShippingType, EStatus, EStatusValue, IHistory, type Orders } from "../../../common/interfaces";

interface HistoryStatusProps {
  display: boolean
  setDisplay: (display: boolean) => void
  info?: IHistory[]
}

export const HistoryStatus: React.FC<HistoryStatusProps> = ({
  display,
  setDisplay,
  info
}) => {
  const [show, setShow] = useState(false);
  const [listOrders, setListOrders] = useState<Orders[]>([]);
  const [user] = useLocalStorage("user", null);
  useEffect(() => {
    // console.log({ display });
    setShow(display);
  }, [display]);
  const hideModal = () => {
    setShow(false);
    setDisplay(false);
    // console.log("hide");
  };
  return (
    <>
      <Modal
        show={show}
        fullscreen={"lg"}
        onHide={() => {
          hideModal();
        }}
      >
        <Modal.Header
          closeButton
          onHide={() => {
            hideModal();
          }}
        >
          <Modal.Title>Estatus</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {info && info.map((statuses: IHistory, i: number) => (
              <ListGroup.Item
                key={i}
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{EStatusValue[statuses.status as keyof typeof EStatusValue]} </div>
                </div>
                <Badge bg="success" pill style={{marginRight: "5px" }}>
                  {statuses.difference_time}
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};
