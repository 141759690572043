
const baseApi = process.env.REACT_APP_API_URL;
export const request = {
    get: async (url: string, params?: Record<string, any>) => {
        const queryParams = params ? `?${new URLSearchParams(params)}` : '';
        return (await fetch(`${baseApi}${url}${queryParams}`)).json()
    },
    post: async (url: string, body: any) => (await fetch(`${baseApi}${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    })).json(),
    put: async (url: string, body: any) => (await fetch(`${baseApi}${url}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    })).json(),
}