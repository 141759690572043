import React, { useEffect, useState } from 'react'
import Navbar from './shared/Navbar'
import Card from './shared/Card'
import { KitchenService } from '../../services/KitchenService'
import { useLocalStorage } from '../../auth/useLocalStorage'
import { usePusher } from '../../context/pusherContext'
import './index.css'
import { type IEvent, type Orders } from '../../common/interfaces'
import { ListModal } from './shared/ListModal'
const Index = () => {
  const pusher = usePusher()
  const [user] = useLocalStorage('user', null)
  const [orders, setOrders] = useState<Orders[]>([])
  const [display, setDisplay] = useState(false)
  const [update, setUpdate] = useState(1)
  const [
    schedules, setSchedules
  ] = useState(false)
  const displayStatus = ['Pending', 'Done', 'Cooking', 'Schedule']
  const openList = (schedules?:boolean) => {
    setSchedules(schedules ?? false)
    setUpdate(update + 1)
    setDisplay(true)
  }
  const getOrders = () => {
    KitchenService.show(user.kitchen_id as string).then((res) => {
      if (res.success && res.object?.orders) {
        setOrders(res.object.orders.filter(order => displayStatus.includes(order.status)))
        setUpdate(update + 1)
      }
    })
  }
  useEffect(() => {
    getOrders()
    const channel = pusher.subscribe('kitchen-channel')
    channel.bind('new-order', (order: IEvent) => {
      if (order.success && order.object && order.object.kitchen_id === user.kitchen_id) {
        getOrders()
        setUpdate(update + 1)
      }
    })
  }, [])
  return (
    <>
      <Navbar openList={openList}/>
      <ListModal display={display} setDisplay={setDisplay} key={update} schedules={schedules}/>
      <div className="container-fluid">
        <div className="row g-3">
          {
            orders.map((item) => (
              <Card key={item.id} {...item} className="col-3" updateCallback={getOrders}/>
            ))
          }
          {
            orders.length === 0 && (
              <div className="col-12">
                <h3 style={{ textAlign: 'center', color: 'white' }}>No hay pedidos pendientes</h3>
              </div>
            )
          }
        </div>

      </div>
    </>
  )
}

export default Index
