import React, { useEffect, useRef, useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { CommonService } from "../../services/CommonService";
import { useLocalStorage } from "../../auth/useLocalStorage";
import { KitchenService } from "../../services/KitchenService";
const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = false;
    if (user) {
      navigate("/");
    }
  }, []);
  const form = useRef<HTMLFormElement>(null);
  const [user, setUser] = useLocalStorage("user", null);
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = async (event?: any) => {
    event?.preventDefault();
    const data = new FormData(form?.current ?? undefined);
    let usuario = (data.get("user") as string) ?? "";
    let password = (data.get("password") as string) ?? "";
    if (usuario === "" || password === "") {
      CommonService.toast("Ingrese usuario y contraseña", "error");
      return;
    }
    const loginInformation = await CommonService.login({ usuario, password });
    console.log({ loginInformation });
    // return
    if (loginInformation.success) {
      KitchenService.createSession({
        user_id: loginInformation.object?.id,
        warehouse_id: loginInformation.object?.warehouse_id,
      }).then((res) => {
        console.log({ res });
        setUser({
          token: loginInformation.message,
          id: loginInformation.object?.id,
          warehouse_id: loginInformation.object?.warehouse_id,
          user: loginInformation.object,
          kitchen_id: res.object.id,
        });
        CommonService.toast("Bienvenido", "success");
        setRedirect(true);
        return;
      })
    }
    CommonService.toast(loginInformation.message, "error");

  };
  if (redirect) {
    window.location.replace(process.env.REACT_APP_PREFIX ?? "");
  }
  return (
    <>
      <div className="outside-section container">
        <div className="login-section col-md-4 row justify-content-center">
          <img
            className="img-fluid"
            src={`${process.env.PUBLIC_URL}/bife.png`}
            alt="logo"
            style={{ width: "200px" }}
          />
          <div className="login-form-section">
            <form onSubmit={handleSubmit} ref={form}>
              <div className="relative">
                <input
                  type="text"
                  name="user"
                  placeholder="Usuario"
                  className="form-control"
                />
              </div>
              <div className="relative">
                <input
                  type="password"
                  name="password"
                  placeholder="Contaseña"
                  className="form-control"
                />
              </div>
              <div className="login-stn-section">
                <button className="login-btn" type="submit">
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
