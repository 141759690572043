import React from 'react';
import "./App.css";
import { Route, Routes } from "react-router-dom";
import LogIn from "./Component/Login/Login";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.bundle";

import Home from "./Component/Home";
import PrivateRoute from './routes/PrivateRoute';

import Pusher from "pusher-js"
import { PusherProvider } from "./context/pusherContext";
// Pusher.logToConsole = true;

const pusher = new Pusher(process.env.REACT_APP_PUSHER_CONFIG_KEY ?? '', {
  cluster: process.env.REACT_APP_PUSHER_CONFIG_CLUSTER ?? '',
  forceTLS: true,
})

function App() {
  return (
    <PusherProvider pusher={pusher}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }></Route>
        <Route path="/login" element={<LogIn />} />
      </Routes>
    </PusherProvider>
  );
}

export default App;
