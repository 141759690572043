import React from "react";
// import { Box, Modal, Typography } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "../index.css";
import { Orders } from "../../../common/interfaces";

interface DetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: Orders; 
}
function DetailsModal({ open, setOpen, data }: DetailsModalProps) {
  const hideModal = () => {
    setOpen(false);
    // setDisplay(false);
    // console.log("hide");
  };
  const handleClose = () => setOpen(false);

  return (
    <Modal
      show={open}
      fullscreen={"lg"}
      onHide={() => {
        hideModal();
      }}
    >
        {
          data && (
            <>
              <Modal.Header closeButton>
                  <div className="fw-bold">Orden # {data.id}</div>
              </Modal.Header>
              <Modal.Body>
                <div className="ms-2 me-auto">
                  <strong>Cliente: </strong> {data.cliente.nombre} <br />
                  <strong>Fecha: </strong> {data.fecha} <br />
                  <strong>Total: </strong> {data.total} <br />
                  <strong>Estado: </strong> {data.status} <br />
                  {
                    data.schedule && (
                      <>
                        <strong>Fecha de Agenda: </strong> {data.schedule} <br />
                      </>
                    )
                  }
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.detalles.map((detalle) => (
                        <tr key={detalle.id}>
                          <td>{detalle.producto.name}</td>
                          <td>{detalle.cantidad}</td>
                          <td>{detalle.precio}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* {JSON.stringify(data.detalles)} */}
                </div>
              </Modal.Body>
            </>
          )
        }
    </Modal>
  );
}

export default DetailsModal;
