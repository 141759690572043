import React, { useState } from "react";
import "../index.css";
import Counter from "./Counter";
import {
  EColorStatus,
  EPaymentStatus,
  EShippingType,
  EStatusValue,
  IHistory,
  type Orders,
} from "../../../common/interfaces";
import { KitchenService } from "../../../services/KitchenService";
import moment from "moment";

const Card: React.FC<
  Orders & { className?: string; updateCallback?: () => void }
> = (props) => {
  const { id, className, updateCallback, status } = props;
  const [disable, setDisable] = useState(false);
  const startOrder = (orderId: string) => {
    setDisable(true);
    KitchenService.trackOrderStatus(orderId, {
      status: "Cooking",
    }).then((res) => {
      setDisable(false);
      updateCallback && updateCallback();
    });
  };
  const setDoneOrder = (orderId: string) => {
    setDisable(true);
    KitchenService.trackOrderStatus(orderId, {
      status: "Done",
    }).then(() => {
      setDisable(false);
      updateCallback && updateCallback();
    });
  };
  const setShipedOrder = (orderId: string, saleType?: string) => {
    setDisable(true);
    KitchenService.trackOrderStatus(orderId, {
      status: saleType === "shipped" ? "InTransit" : "Shipped",
    }).then(() => {
      setDisable(false);
      updateCallback && updateCallback();
    });
  };

  return (
    <>
      <div className={className}>
        <div
          className={`card ${
            EColorStatus[status as keyof typeof EColorStatus]
          }`}
          style={{ height: "550px", overflow: "auto" }}
        >
          <div className="card-body">
            <div className="card-title row">
              <div className="col-6" style={{paddingTop: "5px", "fontSize": "18px"}}>
                #{id}
              </div>
              <div className="col">
                <Counter
                  dateToFormat={props.timeFromCooking}
                  status={props.status}
                  info={props.history}
                />

              </div>
            </div>
            <div className="card-order-detail row">
              <div className="order-detail-one-section col-12 row">
                <div className="card-section-one col-12">
                  <p className="fw-bold" style={{ fontSize: "14px" }}>
                    Cliente:
                    {props?.cliente == null ? (
                      <span style={{fontWeight: 'normal'}}> Público en General</span>
                    ) : (
                      <span style={{fontWeight: 'normal'}}> {props?.cliente?.nombre}</span>
                    )}
                  </p>
                </div>
                <div className="col-12">
                  <p className="fw-bold" style={{ fontSize: "14px" }}>
                    Estatus:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {EStatusValue[props.status as keyof typeof EStatusValue]}
                    </span>
                  </p>
                </div>
                <div className="card-section-two col-12"></div>
                <div className="col-12">
                  <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Tipo:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {EShippingType[
                        props.shipped_type as keyof typeof EShippingType
                      ] ?? ""}
                    </span>
                  </p>
                  <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Pago:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {EPaymentStatus[
                        props.payment_status as keyof typeof EPaymentStatus
                      ] ?? ""}
                    </span>
                  </p>
                  {props.schedule !== "" && props.schedule !== null && (
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Agendado:{" "}
                      <span style={{ fontWeight: "normal", }}>
                        {moment(props.schedule).format("DD/MM/Y HH:MM A")}
                      </span>
                    </p>
                  )}
                  {props.notes_order !== "" && props.notes_order !== null && (
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Notas Extras:{" "}
                      <span style={{ fontWeight: "normal", }}>
                        {props.notes_order}
                      </span>
                    </p>
                  )}
                </div>
              </div>

              <div className="col-12">
                <ol
                  className="list-group list-group-numbered"
                  style={{ height: "200px", overflow: "auto" }}
                >
                  {props.detalles
                    .filter(
                      (element) =>
                        element.options !== "" || element.producto.cocina === 1
                    )
                    .map((detalle, i) => (
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start"
                        key={detalle.id}
                      >
                        <div
                          className="ms-2 me-auto"
                          style={{ fontSize: "12px" }}
                        >
                          <div className="fw-bold">
                            {detalle?.producto.name}
                          </div>
                          {detalle.options !== "" &&
                            detalle.options?.split(",").map((option, i) => (
                              <span key={i}>
                                - {option}
                                <br />
                              </span>
                            ))}
                        </div>
                        <span className="badge bg-primary rounded-pill">
                          {Number(detalle.cantidad).toFixed(2)}
                        </span>
                      </li>
                    ))}
                </ol>
              </div>
            </div>
          </div>
          <div className="btn-group" role="group">
            {status === "Schedule" && (
              <>
                <button
                  disabled={disable}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    startOrder(`${id}`);
                  }}
                >
                  Iniciar
                </button>
              </>
            )}
            {status === "Pending" && (
              <>
                <button
                  disabled={disable}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    startOrder(`${id}`);
                  }}
                >
                  Iniciar
                </button>
              </>
            )}
            {status === "Cooking" && (
              <>
                <button
                  disabled={disable}
                  type="button"
                  className="btn btn-warning"
                  onClick={() => {
                    setDoneOrder(`${id}`);
                  }}
                >
                  Terminar
                </button>
              </>
            )}
            {status === "Done" && (
              <>
                <button
                  disabled={
                    disable ||
                    (props.payment_status === "pending" &&
                      props.shipped_type !== "shipped" &&
                      props.shipped_type !== "inRoom")
                  }
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    setShipedOrder(`${id}`, props.shipped_type);
                  }}
                >
                  {props.shipped_type === "shipped"
                    ? "Entregar a repartidor"
                    : "Entregar"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
