import React, { useEffect, useState } from 'react'
import '../index.css'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Nv from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import PersonIcon from '@mui/icons-material/Person'
import { useLocalStorage } from '../../../auth/useLocalStorage'
import { CommonService } from '../../../services/CommonService'
import { KitchenService } from '../../../services/KitchenService'
import ListGroup from 'react-bootstrap/ListGroup'

interface NavbarProps {
  openList: (schedules?:boolean) => void
}

const Navbar: React.FC<NavbarProps> = ({ openList }) => {
  const [user] = useLocalStorage('user', null)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [warehouse, setWarehouse] = useState<string>()
  useEffect(() => {
    CommonService.getWarehouse(user.warehouse_id).then((res) => {
      setWarehouse(res.name)
    })
  }, [])
  const expand = ''
  const logOut = () => {
    Promise.all([
      CommonService.logout(user?.id),
      KitchenService.update(user.kitchen_id, {
        status: 'close'
      })
    ]).then(() => {
      localStorage.clear()
      window.location.reload()
    })
  }
  return (
    <>
      <Nv key={expand} expand={expand} className="bg-body-tertiary mb-3">
        <Container fluid>
          <Nv.Brand href="#">
            <img
              className="img-fluid"
              src={`${process.env.PUBLIC_URL}/bife.png`}
              alt="logo"
              style={{ width: '50px' }}
            />
          </Nv.Brand>
          
          <Nv.Text>
            SUCURSAL: {warehouse ?? ''}
          </Nv.Text>
          
          <Nv.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleShow}/>
          <Nv.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <PersonIcon /> {user.user.nombre ?? ''}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ListGroup>
                <ListGroup.Item>
                  <Nav.Link onClick={logOut}>Cerrar Sesión</Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Nav.Link onClick={() => {
                    openList()
                    setShow(false)
                  }}>
                    Historico de Pedidos
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Nav.Link onClick={() => {
                    openList(true)
                    setShow(false)
                  }}>
                    Pedidos Agendados
                  </Nav.Link>
                </ListGroup.Item>
              </ListGroup>
            </Offcanvas.Body>
          </Nv.Offcanvas>
        </Container>
      </Nv>
    </>
  )
}

export default Navbar
